import React, { Component, Fragment } from 'react'

import './App.css'

class App extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Fragment>
                <div>Django with React front here for Video Platform!</div>
            </Fragment>
        )
    }
}

export default App
